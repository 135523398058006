@import '../../../../styles/customMediaQueries.css';

.heading {
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.subheading {
  max-width: 65ch;
  font-size: 16px;
  line-height: 22.4px;
  color: var(--colorGrey500);
  text-align: center;
  text-wrap: balance;
  margin: 0px auto;
}

.questionsContainer {
  max-width: var(--contentMaxWidthPages);
  margin: 0px auto;
  margin-top: 75px;
  margin-bottom: 100px;
  padding: 0 32px;
}

.questionsSegment {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}

.faqItem {
  /* margin-bottom: 10px; */
  padding: 10px 0px;
  border-top: 1px solid #ebebeb;
  overflow: hidden;
}

.question {
  padding: 15px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.answer-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
  background-color: #fff;
  & :global(p) {
    font-size: 18px;
    margin: 0px !important;
  }
}

.answer-inner-wrapper {
  padding: 10px 15px;
}

.icon {
  transition: all 300ms;
}

.answerOpenIcon {
  rotate: 180deg;
}
