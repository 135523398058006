@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 0 32px;
  justify-content: stretch;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--marketplaceColor);
  border-radius: 14px;

  @media (--viewportMedium) {
    padding: 30px;
    margin: 60px auto 0px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 55px 48px;
    margin: 60px auto 30px;
  }
}

.root .leftSide {
  & h2 {
    max-width: unset;
    text-align: left;
    color: var(--colorWhite);
    font-size: 32px;
    line-height: 35px;
    font-weight: var(--fontWeightBold);

    @media (--viewportLarge) {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 35px;
    }
  }

  & p {
    max-width: 500px;
    text-align: left;
    color: var(--colorBlack);
    font-size: 16px;
    line-height: 22px;
    font-weight: var(--fontWeightSemiBold);
    letter-spacing: 1%;
  }
}

.centerSide {
  justify-content: center;
  display: none;

  @media (--viewportMedium) {
    display: flex;
    & img {
      width: 120px;
      position: absolute;
      bottom: 0;
      right: 60px;
    }
  }

  @media (--viewportLarge) {
    position: relative;
    padding: 0 50px;

    & img {
      width: 115px;
      bottom: -48px;
      right: unset;
    }
  }
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 30px;

  & a {
    margin: 0;
    background-color: var(--colorWhite);
    color: var(--marketplaceColor);
    border-radius: 104px;
    font-weight: var(--fontWeightBold);
    box-shadow: none;
    padding: 10px 26px;
    text-align: center;
    max-width: 200px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
  }
}
